import React, { useContext, useEffect, useState } from "react"
import { SalesEventHeroProps } from "./SalesEventHero.d"
import { LanguageContext } from "../../../../contexts/Language"
import tw from "twin.macro"
import { Image } from "../../../atoms/Image"
import { ButtonLink } from "../../../atoms/Button"
import { AnimatePresence, motion } from "framer-motion"
import Icon from "../../../atoms/Icon"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../../helpers"
import { css } from "@emotion/react"
import useInterval from "../../../../hooks/useInterval"
import { HeaderContext } from "../../../../contexts/Header"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import LottieJson from "../../../../animations/AugustEventHomepage.json"
import LottieJsonMobile from "../../../../animations/AugustEventHomepageMobile.json"
import Lottie from "react-lottie-player"
import RetentionSalesEventItem from "./RetentionSalesEventItem"
import StaticCarouselItem from "../HeroCarousel/StaticCarouselItem"
import { lang } from "moment"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"

/**
 * Hero that gets used during sales events.
 *
 * @param {SalesEventHeroProps} props React Props
 * @param {any[]} props.salesEvent indicates whether this component is active
 * @return {JSX.Element}
 * @todo Destructure objects, create bindings for image data objects
 */

const SalesEventHero: React.FC<SalesEventHeroProps> = ({
  salesEvent,
  series,
  mainImage,
  mainImageAlt,
  mainImageMobile,
  mainImageMobileAlt,
  vehicle,
  background,
  backgroundMobile,
  slideIndex,
  setSlideIndex,
  slideCount,
  ctaArray,
  eventForefrontImage,
  eventVideo,
  slideData,
  disclaimers,
  disclaimersIconColor,
}) => {
  const [state, dispatch] = useContext(DisclaimersContext)
  const { _, language } = useContext(LanguageContext)
  const formattedEndDate = salesEvent?.formattedEndDate
  const [[page, direction], setPage] = useState([slideIndex, 0])
  const [isHovered, setIsHovered] = useState(false)
  const { toggleMenu } = useContext(HeaderContext)
  const timeout = 8000
  const [loaded, setLoaded] = useState(false)

  const paginate = (newDirection: number) => {
    if (page === 0 && newDirection === -1) {
      setPage([slideCount - 1, newDirection])
      setSlideIndex(slideCount - 1)
    } else if (page === slideCount - 1 && newDirection === 1) {
      setPage([0, newDirection])
      setSlideIndex(0)
    } else {
      setPage([page + newDirection, newDirection])
      setSlideIndex(page + newDirection)
    }
  }

  // Setting timer
  useInterval(
    () => {
      paginate(1)
    },
    timeout,
    isHovered
  )

  //adding this to prevent the content from shifting when the menu is open and the carousel changes slides
  useEffect(() => {
    if (toggleMenu) {
      setIsHovered(true)
    } else {
      setIsHovered(false)
    }
  }, [toggleMenu])

  const variantsDesktop = {
    enter: (direction: number) => {
      return {
        zIndex: 0,
        x: direction >= 0 ? 1500 : -1500,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: "60%",
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1500 : -1500,
        opacity: 0,
      }
    },
  }

  const variantsMobile = {
    enter: (direction: number) => {
      return {
        x: direction >= 0 ? 800 : -800,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 800 : -800,
        opacity: 0,
      }
    },
  }

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  return (
    <article
      className="group"
      css={[
        tw`w-full grid grid-cols-1 relative h-[700px]`,
        language === "en" && tw`h-[760px]`,
        tw`sm:(h-[780px])`,
        tw`md:(bg-transparent relative h-[900px] max-h-[1024px])`,
        tw`lg:(h-auto max-h-[600px])`,
        tw`xl:(max-h-[600px]) desktop-hd:(max-h-[700px])`,
      ]}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {/* Desktop Controls */}
      <button
        css={[
          tw`hidden`,
          tw`lg:(hidden absolute left-12 top-[45%] -translate-y-1/2 z-50 group-hover:flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full transition-all hover:bg-gray-400 focus-visible:(bg-gray-400))`,
        ]}
        onClick={() => {
          paginate(-1)
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "hero|left arrow",
          })
        }}
        analytics-id="left arrow:hero:hero"
      >
        <Icon.Chevron direction="left" color="black" css={[tw`h-5`]} />
      </button>
      <button
        css={[
          tw`hidden`,
          tw`lg:(hidden absolute right-12 top-[45%] -translate-y-1/2 z-50 group-hover:flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full transition-all hover:bg-gray-400 focus-visible:(bg-gray-400))`,
        ]}
        onClick={() => {
          paginate(1)
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "hero|right arrow",
          })
        }}
        analytics-id="right arrow:hero:hero"
      >
        <Icon.Chevron direction="right" color="black" css={[tw`h-5`]} />
      </button>
      {/* Mobile Controls */}
      <button
        css={[
          tw`absolute left-[32%] bottom-5 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gray-900 rounded-full transition-all`,
          tw`md:(left-[41%])`,
          tw`lg:(hidden)`,
        ]}
        onClick={() => {
          paginate(-1)
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "hero|left arrow",
          })
        }}
        analytics-id="left arrow:hero:hero"
      >
        <Icon.Chevron direction="left" color="white" css={[tw`h-5`]} />
      </button>
      <button
        css={[
          tw`absolute right-[32%] bottom-5 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gray-900 rounded-full transition-all`,
          tw`md:(right-[41%])`,
          tw`lg:(hidden)`,
        ]}
        onClick={() => {
          paginate(1)
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "hero|right arrow",
          })
        }}
        analytics-id="right arrow:hero:hero"
      >
        <Icon.Chevron direction="right" color="white" css={[tw`h-5`]} />
      </button>

      {slideData?._type === "heroCarouselItem" && (
        <>
          <div
            css={[
              tw`hidden`,
              tw`lg:(relative flex col-start-1 col-end-2 col-span-2 row-start-1 row-end-2 grid-cols-1 grid-rows-1 p-0 m-0 mb-10)`,
            ]}
          >
            {language === "es" && (
              <Image
                imageData={background}
                objectFit="contain"
                aria-hidden={true}
                css={[tw`object-cover`]}
              />
            )}
          </div>
          {!eventVideo && (
            <div
              css={[
                tw`relative flex col-start-1 col-end-2 col-span-2 row-start-1 row-end-2 grid-cols-1 grid-rows-1 p-0 m-0 mb-10 max-h-[400px]`,
                tw`md:(max-h-[450px])`,
                tw`lg:(max-h-[600px] relative flex items-center justify-center col-start-1 col-end-2 col-span-2 row-start-1 row-end-2 grid-cols-1 grid-rows-1 p-0 m-0)`,
                tw`xl:(max-h-[600px])`,
                tw`desktop-hd:(max-h-[700px] h-[700px] overflow-hidden)`,
              ]}
            >
              {language === "en" && (
                <Lottie
                  loop
                  animationData={LottieJson}
                  play
                  style={{
                    width: "100%",
                    height: "100%",
                    // backgroundSize: 'cover',
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
                  css={[tw`hidden lg:(block)`]}
                />
              )}
            </div>
          )}

          <div
            css={[
              tw`relative flex col-start-1 col-span-2 row-start-1 p-0 m-0 h-[340px]`,
              tw`md:(min-h-[550px])`,
              tw`lg:(hidden)`,
            ]}
          >
            {language === "en" && (
              <Lottie
                loop
                animationData={LottieJsonMobile}
                play
                style={{
                  width: "100%",
                  height: "100%",
                  // backgroundSize: 'cover',
                  backgroundPosition: "center",
                  objectFit: "cover",
                }}
                css={[tw`relative`]}
                rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              />
            )}
            {language === "es" && (
              <Image
                imageData={backgroundMobile}
                objectFit="contain"
                aria-hidden={true}
                css={[
                  tw`relative w-full object-cover`,
                  `background-position: top center;`,
                ]}
              />
            )}
          </div>
          {language === "es" && (
            <div css={[tw`w-full absolute lg:(hidden)`]}>
              <Image
                imageData={salesEvent.eventImageMobile?.image}
                objectFit="cover"
                transparentPreview
                hideAlt
                aria-label={salesEvent.eventImageMobile?.image?.asset?.altText}
                css={[tw`mx-auto p-6 max-w-[263px] lg:(hidden)`]}
              />
            </div>
          )}
          <div
            css={[tw`absolute -top-1 w-full flex justify-center lg:(hidden)`]}
          >
            {language === "en" && (
              // <Lottie
              //   loop
              //   animationData={LottieCelebrate}
              //   play
              //   style={{
              //     // backgroundSize: 'cover',
              //     backgroundPosition: "center",
              //     objectFit: "cover",
              //   }}
              //   rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              //   css={[tw`max-w-[200px] lg:(hidden)`]}
              // />
              <></>
            )}
            {language === "es" && (
              // <Lottie
              //   loop
              //   animationData={LottieCelebrateSpanish}
              //   play
              //   style={{
              //     // backgroundSize: 'cover',
              //     backgroundPosition: "center",
              //     objectFit: "cover",
              //   }}
              //   rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              //   css={[tw`max-w-[200px] lg:(hidden)`]}
              // />
              <></>
            )}
          </div>
          {language === "es" && (
            <Image
              imageData={eventForefrontImage?.image}
              //objectFit="contain"
              transparentPreview
              hideAlt
              css={[
                tw`hidden absolute top-20 right-[15%] max-w-[350px] lg:(block) 2xl:(right-[18%]) desktop-hd:(right-[28%] top-36)`,
              ]}
            />
            // <div css={[tw`absolute w-full top-1`]}>
            //   <Image
            //     imageData={salesEvent.eventImageMobile?.image}
            //     objectFit="cover"
            //     transparentPreview
            //     hideAlt
            //     aria-label={salesEvent.eventImageMobile?.image?.asset?.altText}
            //     css={[
            //       tw`mx-auto w-[180px] max-w-[180px] lg:(hidden)`,
            //       language === "es" && tw`w-[150px] max-w-[150px] lg:(hidden)`,
            //     ]}
            //   />
            // </div>
          )}

          {/* <div
            css={[
              tw`block !normal-case mt-2.5 font-semibold text-lg absolute top-9 p-6`,
              tw`lg:(hidden)`,
              `color: ${salesEvent?.endDateColor?.hex};`,
            ]}
          >
            {_("Event Ends")} {formattedEndDate}
          </div> */}
          <div
            css={[
              tw`absolute top-0 z-0 h-[740px]`,
              tw`md:(h-[360px] overflow-hidden)`,
              css`
                z-index: -1;
              `,
              tw`lg:(hidden)`,
            ]}
          >
            {/* <Image
              imageData={backgroundMobile}
              objectFit="contain"
              aria-hidden={true}
            /> */}
          </div>
          <section
            css={[
              tw`col-start-1 col-end-2 row-start-1 grid w-full mx-auto relative h-[740px] max-h-[740px]`,
              tw`md:(max-h-[1024px])`,
              // tw`md:(col-start-1 col-end-2 row-start-1 grid w-full mx-auto)`,
              tw`lg:(bg-transparent px-10 col-end-2 row-start-1 grid grid-cols-2 max-w-desktop w-full mx-auto h-[760px] max-h-[660px])`,
              tw`xl:(h-[670px] max-h-[670px])`,
              tw`desktop-hd:(h-[700px] max-h-[700px])`,
            ]}
          >
            <div
              css={[
                tw`row-span-1 col-start-1 row-start-1 col-span-2 flex items-center justify-center mt-14 h-[240px]`,
                // tw`md:(row-span-1 col-start-1 row-start-2 col-span-2 flex items-center justify-center mt-24 mb-24 h-[170px])`,
                tw`lg:(row-span-1 col-start-1 row-start-1 row-end-3 col-span-1 flex items-center justify-end mt-32 relative)`,
                tw`xl:(row-span-1 col-start-1 row-start-1 row-end-3 col-span-1 flex items-center justify-end mt-32 relative)`,
                tw`desktop-hd:(mt-[15rem])`,
              ]}
            >
              {/* MAIN VEHICLE IMAGE */}
              {mainImage && (
                <AnimatePresence>
                  <motion.div
                    key={slideIndex}
                    custom={direction}
                    variants={variantsDesktop}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      duration: 1,
                      delay: 0,
                    }}
                    css={[
                      tw`hidden`,
                      tw`lg:(absolute bottom-[-10rem] flex justify-center w-full)`,
                      tw`xl:(w-full)`,
                    ]}
                  >
                    <Image
                      imageData={mainImage.image}
                      css={[
                        tw`hidden`,
                        tw`lg:(block mb-36 ml-[28rem] min-h-[240px])`,
                        tw`xl:(block mb-4 ml-[24rem] max-h-[320px])`,
                        tw`2xl:(mb-4 ml-[33rem] max-h-[350px])`,
                        tw`desktop-hd:(mb-10 max-h-[380px])`,
                        language === "en" &&
                          tw`mb-16 lg:(mb-16) 2xl:(mb-16) desktop-hd:(mb-16)`,
                      ]}
                      objectFit="contain"
                      aria-hidden={true}
                    />
                  </motion.div>
                </AnimatePresence>
              )}

              {/* MAIN VEHICLE IMAGE MOBILE */}
              {/* disclaimers */}
              {disclaimers && (
                <div
                  css={[
                    tw`absolute top-[280px] right-10 z-30`,
                    tw`md:(top-[380px])`,
                    tw`lg:(hidden)`,
                    disclaimersIconColor === "light"
                      ? tw`text-white`
                      : tw`text-black`,
                  ]}
                >
                  {parseDisclaimerBlocks(disclaimers, selection =>
                    dispatch(toggleDisclaimersModal(selection))
                  )}
                </div>
              )}
              {mainImageMobile && (
                <AnimatePresence>
                  <motion.div
                    key={slideIndex}
                    custom={direction}
                    variants={variantsMobile}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{ duration: 1, delay: 0 }}
                    css={[
                      tw`w-full absolute flex justify-center`,
                      tw`md:(mt-[8rem])`,
                      language === "es" && tw`mt-6 md:(mt-[9rem])`,
                      tw`lg:(hidden)`,
                    ]}
                  >
                    <Image
                      imageData={mainImageMobile.image}
                      css={[
                        tw`h-[170px] object-contain z-50`,
                        // `@media screen and (min-width: 390px) {
                        //   margin-top: 8rem;
                        // }
                        // `,
                        language === "es" && tw`mt-14 md:(mt-[9rem])`,
                        tw`md:(h-[240px] min-h-[240px] object-contain mt-10)`,
                        tw`lg:(hidden)`,
                      ]}
                      objectFit="contain"
                      aria-hidden={true}
                    />
                  </motion.div>
                </AnimatePresence>
              )}
            </div>
            <div
              css={[
                tw`col-start-1 col-span-2 row-start-2 text-3xl font-sans uppercase mt-0 transition delay-300 duration-300 ease-in-out w-full h-[275px]`,
                tw`sm:(h-[310px] mt-4)`,
                tw`md:(h-[310px] mt-4)`,
                `@media screen and (max-width: 1024px) {
       background: ${
         salesEvent?.panelColor
           ? `rgba(${parseInt(
               salesEvent.panelColor.hex.slice(1, 3),
               16
             )}, ${parseInt(
               salesEvent.panelColor.hex.slice(3, 5),
               16
             )}, ${parseInt(salesEvent.panelColor.hex.slice(5, 7), 16)}, ${
               salesEvent.panelColor.alpha || 1
             })`
           : "#ffffff"
       };
    }`,
                tw`md:(mt-[9.5rem])`,
                tw`lg:(w-auto row-start-2 min-w-[460px] h-[300px] max-h-[300px] text-5xl col-start-1 col-span-1 px-8 pb-4 pt-8 my-auto rounded-2xl backdrop-blur-sm mt-8)`,
                tw`xl:(pb-8 mr-32 ml-16 mt-0)`,
                `@media (min-width: 1024px) {
      background: ${
        salesEvent?.panelColor
          ? `rgba(${parseInt(
              salesEvent.panelColor.hex.slice(1, 3),
              16
            )}, ${parseInt(
              salesEvent.panelColor.hex.slice(3, 5),
              16
            )}, ${parseInt(salesEvent.panelColor.hex.slice(5, 7), 16)}, ${
              salesEvent.panelColor.alpha || 1
            })`
          : "#ffffff"
      };
    }`,
              ]}
            >
              {" "}
              <div css={[tw`flex flex-col justify-center`]}>
                <div css={[tw`flex justify-center w-full lg:(mt-0 w-auto)`]}>
                  {language === "es" && (
                    <Image
                      imageData={eventForefrontImage?.image}
                      objectFit="contain"
                      transparentPreview
                      hideAlt
                      css={[
                        tw`w-full mt-0 mb-4 max-w-[350px] md:(max-w-[350px] mt-10) lg:(hidden)`,
                      ]}
                    />
                  )}
                  {language === "en" && (
                    <Image
                      imageData={salesEvent.eventImageMobile?.image}
                      objectFit="contain"
                      transparentPreview
                      hideAlt
                      css={[
                        tw`w-full mt-0 mb-4 max-w-[350px] md:(max-w-[350px] mt-10) lg:(hidden)`,
                      ]}
                    />
                  )}
                  {/* <Lottie
                    loop
                    animationData={LottieBoombox}
                    play
                    style={{
                      width: "100%",
                      height: "100%",
                      // backgroundSize: 'cover',
                      backgroundPosition: "center",
                      objectFit: "cover",
                    }}
                    rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
                    css={[tw`relative hidden`, language === "en" && tw`block`]}
                  /> */}
                  {/* <Lottie
                    loop
                    animationData={LottieBoomboxSpanish}
                    play
                    style={{
                      width: "100%",
                      height: "100%",
                      // backgroundSize: 'cover',
                      backgroundPosition: "center",
                      objectFit: "cover",
                    }}
                    rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
                    css={[tw`relative hidden`, language === "es" && tw`block`]}
                  /> */}
                  <Image
                    imageData={salesEvent.eventImage?.image}
                    objectFit="cover"
                    transparentPreview
                    hideAlt
                    aria-label={salesEvent.eventImage?.image?.asset?.altText}
                    css={[
                      tw`hidden w-auto lg:(flex -ml-6 w-[450px])`,
                      language === "es" && tw`lg:(mx-auto mt-0 w-[350px])`,
                    ]}
                  />
                </div>
                <div
                  css={[
                    tw`text-3xl !normal-case font-book text-center px-10 pb-4`,
                    tw`md:(mx-32)`,
                    tw`lg:(block text-3xl text-left mx-0 px-0 py-2)`,
                    language === "es" &&
                      tw`text-2xl font-semibold px-20 lg:(text-2xl)`,
                    `color: ${salesEvent?.endDateColor?.hex};`,
                  ]}
                >
                  {language === "es" && (
                    <span>Evento termina el 3 de Septiembre</span>
                  )}
                  {language === "en" && (
                    <span css={[tw`text-black`]}>
                      {"The New " +
                        vehicle?.year +
                        " " +
                        (series.toLowerCase() === "rav4"
                          ? series.toUpperCase()
                          : series
                              .split(" ")
                              .map(
                                word =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" "))}
                    </span>
                  )}
                </div>
              </div>
              <span
                css={[
                  tw`block  md:(max-w-[590px]) lg:(max-w-[370px])`,
                  `color: ${salesEvent?.taglineColor?.hex};`,
                ]}
              >
                {salesEvent.tagline}
              </span>
              <div
                css={[
                  tw`w-full flex mx-auto justify-center`,
                  tw`md:(w-3/4)`,
                  tw`lg:(w-full)`,
                ]}
              >
                {/* <Image
                  imageData={salesEvent.eventImageMobile?.image}
                  objectFit="cover"
                  transparentPreview
                  hideAlt
                  aria-label={
                    salesEvent.eventImageMobile?.image?.asset?.altText
                  }
                  css={[tw`lg:(hidden)`, language === "es" && tw`p-3`]}
                /> */}
              </div>
              {/* CTAs */}
              {ctaArray && (
                <AnimatePresence>
                  <motion.div
                    css={[
                      tw`mt-3 mb-3 flex grid-cols-2 gap-2 justify-center lg:(justify-start mt-3)`,
                      //tw`md:(max-w-[500px])`,
                    ]}
                    key={slideIndex}
                    animate={{ opacity: 1, x: 0 }}
                    initial={{ opacity: 0, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                  >
                    {ctaArray?.map((cta: any) => (
                      <ButtonLink
                        {...{ [cta?.buttonType]: true }}
                        to={getCTALinkValue(cta)}
                        target={
                          cta?.linkType === "external" ? "_blank" : "_self"
                        }
                        css={[
                          tw`whitespace-nowrap`,
                          tw`lg:w-auto`,
                          cta?.buttonType === "secondaryLight" &&
                            tw`text-gray-900`,
                        ]}
                        aria-label={cta?.title}
                        // secondary
                        key={cta?.title}
                        analytics-id={`${cta?.title}:homepage carousel:${slideIndex}`}
                        onClick={() => {
                          trackTealEvent({
                            tealium_event: "cta_click",
                            coupon_module_text: cta?.title,
                            link_href: `${getCTALinkValue(cta)}`,
                          })
                        }}
                        secondary={cta?.buttonType === "secondaryLight"}
                      >
                        {cta?.title}
                      </ButtonLink>
                    ))}
                  </motion.div>
                </AnimatePresence>
              )}
            </div>
            <div
              css={[
                tw`col-start-1 col-span-2 row-start-1 my-2 pb-4 absolute top-[35%] w-full h-[50px]`,
                tw`sm:(-mt-28 top-[58%])`,
                tw`md:(-mt-12)`,
                tw`lg:(block relative top-0 col-start-1 row-start-3 overflow-visible col-span-2 mt-0 mb-0 h-[130px])`,
                // tw`2xl:(mt-48)`,
                tw`desktop-hd:(-mt-4 top-[50px])`,
              ]}
            >
              {language === "en" && (
                // <Lottie
                //   loop
                //   animationData={LottieCelebrate}
                //   play
                //   style={{
                //     width: "100%",
                //     height: "100%",
                //     // backgroundSize: 'cover',
                //     backgroundPosition: "center",
                //     objectFit: "cover",
                //   }}
                //   rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
                //   css={[
                //     tw`hidden absolute -top-12 max-w-[300px] lg:(block) desktop-hd:(-top-10)`,
                //   ]}
                // />
                <></>
              )}
              {language === "es" && (
                // <Lottie
                //   loop
                //   animationData={LottieCelebrateSpanish}
                //   play
                //   style={{
                //     width: "100%",
                //     height: "100%",
                //     // backgroundSize: 'cover',
                //     backgroundPosition: "center",
                //     objectFit: "cover",
                //   }}
                //   rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
                //   css={[
                //     tw`hidden absolute -top-12 max-w-[300px] lg:(block) desktop-hd:(-top-10)`,
                //   ]}
                // />
                <>
                  {/* {eventForefrontImage && (
                    <Image
                      imageData={eventForefrontImage?.image}
                      //objectFit="contain"
                      transparentPreview
                      hideAlt
                      css={[
                        tw`absolute mt-4 mb-10 hidden max-w-[250px] md:(-mt-40 max-w-[330px]) lg:(-mt-2 ml-36 block) xl:(-mt-16)`,
                        language === "es" && tw`lg:(mt-[-20px] block mb-0)`,
                      ]}
                    />
                  )} */}
                </>
              )}
              <AnimatePresence>
                <motion.div
                  css={[
                    tw`hidden text-lg relative leading-3 font-semibold uppercase text-gray-900 text-center lg:(text-2xl h-[32px] text-right mr-10)`,
                    language === "es" && tw`lg:(block)`,
                  ]}
                  key={slideIndex}
                  animate={{ opacity: 1, x: 0 }}
                  initial={{ opacity: 0, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                >
                  {language === "en"
                    ? vehicle?.year + " " + series
                    : series + " " + vehicle?.year}

                  <span
                    css={[
                      tw`block normal-case font-semibold pt-2 text-xs text-gray-900`,
                      language === "es" && tw`hidden`,
                    ]}
                  >
                    {_("Toyota Safety Sense™ Standard")}
                  </span>
                </motion.div>
              </AnimatePresence>
            </div>
          </section>
          {/* disclaimers */}
          {disclaimers && (
            <div
              css={[
                tw`hidden`,
                tw`lg:(block absolute text-xl bottom-10 right-10 z-30)`,
                disclaimersIconColor === "light"
                  ? tw`text-white`
                  : tw`text-black`,
              ]}
            >
              {parseDisclaimerBlocks(disclaimers, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </div>
          )}
        </>
      )}
      {slideData?._type === "retentionSEHeroItem" && (
        <RetentionSalesEventItem {...slideData} slideIndex={slideIndex} />
      )}
      {slideData?._type === "staticCarouselItem" && (
        <StaticCarouselItem {...slideData} slideIndex={slideIndex} />
      )}

      {/* Indicator */}
      {slideCount > 1 && (
        <div
          css={[
            tw`absolute bottom-0 left-1/2 -translate-x-1/2 flex z-20`,
            tw`md:(bottom-2)`,
          ]}
        >
          {[...Array(slideCount)]?.map((count, i) => {
            return (
              <Indicator
                key={i}
                active={slideIndex === i}
                timeout={timeout}
                isHovered={isHovered}
              />
            )
          })}
        </div>
      )}
    </article>
  )
  // )
}

const Indicator: React.FC<{
  key: number
  active: boolean
  timeout?: number
  isHovered: boolean
}> = ({ key, active, timeout = 0, isHovered }): JSX.Element => {
  return (
    <div
      key={key}
      css={[tw`py-2 relative mx-1 transition-colors bg-transparent`]}
    >
      <div
        css={[
          tw`bg-gray-400 w-8 h-1 border-gray-400 relative overflow-hidden`,
          `border-width: 0.025rem;`,
          active && tw`bg-transparent border-toyotaRed`,
        ]}
      >
        {active ? (
          <span
            css={[
              tw`bg-toyotaRed border-r-2 border-toyotaRed h-full absolute left-0 top-0 bottom-0 w-full`,
              css`
                animation: progressBar ${timeout}ms linear forwards;
                @keyframes progressBar {
                  /* Note:  Animating Width leaves a stutering effect.  Use use Traslate X */
                  from {
                    transform: translateX(-100%);
                  }
                  to {
                    transform: translateX(0%);
                  }
                }
              `,
              isHovered && `animation-play-state: paused;`,
            ]}
            role="presentation"
            className="progress-completed"
          />
        ) : null}
      </div>
    </div>
  )
}

export default SalesEventHero
